// Dashboard.js
import React, { useState } from 'react';
import KPIsPage from './KPIsPage';
import RadiologyReportPage from './RadiologyReportPage';
import { AccessTimeSharp } from '@material-ui/icons';
import { Divider, ButtonGroup, Button } from '@material-ui/core';

const Analytics = () => {
  const [currentPage, setCurrentPage] = useState('kpi');  // 'kpi' or 'radiology'

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <div align="center">
        <ButtonGroup color="primary" variant="contained" aria-label="outlined primary button group">
          <Button onClick={() => handlePageChange('kpi')}>KPI Report</Button>
          <Button onClick={() => handlePageChange('radiology')}>Radiology Report</Button>
        </ButtonGroup>
      </div>
      {currentPage === 'kpi' && <KPIsPage />}
      {currentPage === 'radiology' && <RadiologyReportPage />}


      <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />

      <div align="center">
        <div style={{ color: '#999999', fontSize: '12px', display: 'inline-flex', lineHeight: '22px' }}>
          <AccessTimeSharp style={{ width: '20px', height: '20px', marginRight: '5px' }} />Updated at {new Date().toLocaleTimeString()}
        </div>
      </div>
    </div>
  );
};

export default Analytics;
