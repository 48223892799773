import React from 'react';
import { Divider, Paper, Typography } from '@mui/material'
import {
    CartesianGrid,
    XAxis,
    YAxis,
    LabelList,
    BarChart,
    Bar,
    ResponsiveContainer,
    Cell,
    Label,
    Tooltip
} from 'recharts';
import axios from 'axios';
import useSWR from 'swr';
import { AccessTimeSharp } from '@material-ui/icons';

const BarGraphUrgency = () => {
    const fetcher = (url) =>
        axios.get(url)
            .then((response) => {
                return response.data.requisitionUrgencies;
            })
            .catch((err) => {
                console.log(err);
            });

    const Profiler = () => {
        const { data, error } = useSWR('/api/analytics/requisition_urgencies', fetcher, { refreshInterval: 60000});

        if (!error && data) {
            return (<BarGraph data={data} />);
        } else {
            return (<BarGraph data={[{ name: '', count: 0 }]} />);
        }
    };

    const BarGraph = (props) => {
        const barColors = ['#004C6D', '#3D708F', '#6996B3', '#94BED9']

        return (
            <Paper sx={{ width: '100%', padding: '20px', paddingBottom: '10px' }}>
                <ResponsiveContainer height={200} width={'100%'}>
                    <BarChart layout='vertical' margin={{ right: 20, left: -20, bottom: 20 }} data={props.data}>
                        <CartesianGrid strokeDasharray='1 1' horizontal={false} />
                        <Tooltip />

                        <XAxis type='number'>
                            <Label value='Requisitions' position='bottom' offset={-4} />
                        </XAxis>

                        <YAxis type='category' dataKey='name' orientation='left' >
                            <Label value='Urgency Level' angle={-90} />
                        </YAxis>

                        <Bar dataKey='count' barSize={25}>
                            <LabelList dataKey='count' position='right' style={{ fontWeight: 'bold' }} />
                            {props.data.map((entry, index) => (
                                <Cell key={entry.name} fill={barColors[index]} />
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>

                <Typography variant='h5'>Pending Requisitions by Urgency</Typography>
                <Typography variant='subtitle2'><b>1</b> (2-3 hours), <b>2</b> (24 hours), <b>3</b> (24-72 hours), <b>4</b> (2 weeks)</Typography>

                {/* 
                <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />

                <div style={{ color: '#999999', fontSize: '12px', display: 'inline-flex', lineHeight: '22px' }}>
                    <AccessTimeSharp style={{ width: '20px', height: '20px', marginRight: '5px' }} />Updated at {new Date().toLocaleTimeString()}
                </div>
                */}
            </Paper>
        );
    };

    return (
        <Profiler />
    );
};

export default BarGraphUrgency;
