import React, { useEffect, useState, memo } from 'react';
import { Paper, Typography, Select, MenuItem, Grid } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip, CartesianGrid, Label } from 'recharts';
import axios from 'axios';
import useSWR from 'swr';


// Custom Tooltip Component
const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="label">{`Urgency Level ${payload[0].payload.name}`}</p>
                <p className="intro">{`Average wait time in hours: ${payload[0].value}`}</p>
            </div>
        );
    }

    return null;
};

const UrgencyBarChart = memo(({ urgencyData }) => (
    <ResponsiveContainer width="100%" height={200}>
        <BarChart layout='vertical' margin={{ right: 40, left: -20, bottom: 20 }} data={[urgencyData]}>
            <CartesianGrid strokeDasharray='1 1' horizontal={false} />
            <XAxis type='number'>
                <Label value='Average Completion Time (Hours)' position='bottom' offset={-4} />
            </XAxis>
            <YAxis type='category' dataKey='name'>
                <Label value='Urgency Level' angle={-90} />
            </YAxis>
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey='count' fill='#94BED9' />
        </BarChart>
    </ResponsiveContainer>
));

const BarGraphCompletionTimeUrgency = () => {
    const [timeframe, setTimeframe] = useState(7);
    const fetcher = url => axios.post(url, { timeframe })
                                  .then(response => response.data.averageCompletionUrgency)
                                  .catch(err => console.log(err));
    const { data, error, mutate } = useSWR('/api/analytics/average_completion_time_urgency', fetcher);

    useEffect(() => {
        mutate();
    }, [timeframe, mutate]);

    if (error) return <div>Error loading data...</div>;
    if (!data) return <div>Loading...</div>;

    return (
        <Paper sx={{ padding: '20px' }}>
            <Grid container spacing={2}>
                {data.map((urgencyData, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                        <UrgencyBarChart urgencyData={urgencyData} />
                    </Grid>
                ))}
            </Grid>
            <Grid container direction='row' wrap='nowrap'>
                <Grid item>
                    <Typography variant='h5'>Average Completion Time by Urgency</Typography>
                </Grid>
                <Grid item sx={{ marginLeft: 'auto', marginBottom: '-20px'}}>
                    <Select value={timeframe} onChange={e => setTimeframe(e.target.value)} size='small' width={150} >
                        <MenuItem value={7}>Last 7 days</MenuItem>
                        <MenuItem value={30}>Last 30 days</MenuItem>
                        <MenuItem value={365}>Last 365 days</MenuItem>
                    </Select>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default BarGraphCompletionTimeUrgency;
