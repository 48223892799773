import React, { useEffect, useState } from 'react';
import { Divider, Paper, Typography } from '@mui/material'
import {
    CartesianGrid,
    XAxis,
    YAxis,
    LabelList,
    BarChart,
    Bar,
    ResponsiveContainer,
    Cell,
    Label,
    Tooltip
} from 'recharts';
import axios from 'axios';
import useSWR from 'swr';
import { AccessTimeSharp } from '@material-ui/icons';

const TotalPendingRequisition = () => {
    const fetcher = (url) =>
        axios.get(url)
            .then((response) => {
                return response.data.PatientsInQueueTotal;
            })
            .catch((err) => {
                console.log(err);
            });

    const Profiler = () => {
        const { data, error } = useSWR('/api/analytics/requisition_total', fetcher, { refreshInterval: 60000});

        if (!error && data) {
            return (<Total data={data} />);
        } else {
            return (<Total data={0} />);
        }
    };

    const Total = (props) => {
        return (
            <Paper sx={{ width: '100%', padding: '20px', paddingBottom: '10px' }}>


                <Typography variant='h5'>Pending Requisitions:</Typography>
                <Typography variant='h5' style={{color: '#6996B3'}}>{props.data}</Typography>
                {/* <Typography variant='subtitle2'>&#8203;</Typography> */}

            </Paper>
        );
    };

    return (
        <Profiler />
    );
};

export default TotalPendingRequisition;