import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Paper, Typography, Grid, Select, MenuItem } from "@mui/material";

const RequisitionLineChart = () => {
  const [data, setData] = useState([]);
  const [timeframe, setTimeframe] = useState('year'); // default timeframe

  const fetchData = async () => {
    try {
      const response = await axios.post('/api/analytics/requisition_timeframe_data', { timeframe });
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [timeframe]);

  return (
    <Paper sx={{ width: "100%", padding: "20px", paddingBottom: "10px" }}>
        <ResponsiveContainer height={200} width={"100%"}>
            <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="count" stroke="#3D708F" activeDot={{ r: 8 }} />
            </LineChart>
            
        </ResponsiveContainer>
        <Grid container direction='row' wrap='nowrap'>
                    <Grid item>
                        <Typography variant='h5'>Recently Completed Requisitions</Typography>
                    </Grid>

                    <Grid item sx={{ marginLeft: 'auto', marginBottom: '-20px'}}>
                        <Select 
                          value={timeframe}
                          onChange={(e) => setTimeframe(e.target.value)}
                          size='small'
                          width={150}
                        >
                            <MenuItem value="week">Week</MenuItem>
                            <MenuItem value="month">Month</MenuItem>
                            <MenuItem value="year">Year</MenuItem>
                            <MenuItem value="2years">2 Years</MenuItem>
                        </Select>
                    </Grid>
                </Grid>

    </Paper>
    );
};

export default RequisitionLineChart;


