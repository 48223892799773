import React, { useEffect, useState } from 'react'
import axios from 'axios'
import indicateDate from "./supportFunction/indicateDate";
import protocolStyle from "./supportFunction/protocolStyle";
import {
    Button,
    ButtonGroup,
    makeStyles,
} from '@material-ui/core'
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { Alert, Box, Snackbar } from "@mui/material";
import renderCellExpand from './supportFunction/renderCellExpand';
import indicateUrgency from './supportFunction/indicateUrgency';
import indicatePriority from './supportFunction/indicatePriority';
import { WorklistEditDialog } from './helpers';
import { DeleteDialog } from './helpers';

/**
 * Prepare class styles.
 * @type {(props?: any) => ClassNameMap<"contain"|"buttonStyle"|"table">}
 */
const useStyles = makeStyles({
    table: {
        minWidth: 650
    },
    contain: {
        marginTop: 10
    },
    buttonStyle: {
        margin: 5
    },
    gridHeaderSeperatorless: {
        //backgroundColor: "#64B5F6",
        '& > .MuiDataGrid-columnSeparator': {
            visibility: 'hidden',
            width: '0px'
        }
    },
    toolbarButton: {
        marginLeft: "3px",
        marginTop: "4px",
        top: 0
    },
});

/**
 * This function returns the Protocol page.
 * @returns {JSX.Element}
 * @constructor
 */
export const Worklist = () => {
    const classes = useStyles();

    const [data, setData] = useState({});
    const [fullData, setFullData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [editData, setEditData] = useState(null);
    const [protocols, setProtocols] = useState(null);

    /**
     * Load initial data
     */
    useEffect(() => {
        fetchData();

        axios.get("/api/auth/protocols")
            .then(res => {
                setProtocols(res.data.objProtocols);
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    /**
     * Fetch requisition data from database and update datagrid
     */
    const fetchData = () => {
        axios.get('/api/auth/worklist', { params: { role: localStorage.getItem('role') } })
            .then((res) => {
                setFullData(res.data.requisitions);

                let acc = [];

                for (let r of res.data.requisitions) {
                    let sequences = '';
                    let protocols = '';

                    for (let sequence of r.sequences) {
                        if (sequences === '') {
                            sequences += sequence.sequenceID.sequence;
                        } else {
                            sequences += ', ' + sequence.sequenceID.sequence;
                        }
                    }

                    for (let protocol of r.protocols) {
                        if (protocols === '') {
                            protocols += protocol.protocolID.protocol;
                        } else {
                            protocols += ', ' + protocol.protocolID.protocol;
                        }
                    }

                    acc.push(
                        {
                            id: r.id.toString(),
                            urgency: r.urgency.toString(),
                            priority: r.priority.toString(),
                            dateCreated: new Date(r.dateCreated).toLocaleString('en-US', {day: "numeric", year: "numeric", month: "long", hour: "numeric", minute: "numeric"}),
                            isolationPrecaution: r.isolationPrecaution.isolationPrecaution,
                            ward: r.ward,
                            phin: r.phin.toString(),
                            patientFirstName: r.patientFirstName,
                            patientLastName: r.patientLastName,
                            timing: r.timing.toString() + ' minutes',
                            clinicalInformation: r.clinicalInformation,
                            protocols: protocols,
                            sequences: sequences,
                            approvalLevel: r.approvalLevel,
                            confidence: r.confidence,
                            bedBlocking: r.bedBlocking.toString()
                        }
                    );
                }

                setData(acc, setIsLoading(false));
            })
            .catch((err) => {
                console.log(err);
            })
    }

    /**
     * Setup snackbar props.
     * snackbarOpen: Open state for snackbar popup messages.
     * snackMessage: Message to display on snackbar.
     * snackMessage: Type of snackbar to show. Affects color and icon.
     * Values: "success", "error", "warning", "info".
     */
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState();
    const [snackSeverity, setSnackSeverity] = useState();

    /**
     * Shows snackbar.
     * @param message The message to be displayed on snackbar.
     * @param severity The type of snackbar to show. Types: "success", "error", "warning", "info".
     */
    const showSnack = (message, severity) => {
        setSnackMessage(message);
        setSnackSeverity(severity);
        setSnackbarOpen(true);
    }

    /**
     * Handles snackbar close event.
     * @param event
     * @param reason
     */
    const handleSnackClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackbarOpen(false);
    };

    /**
     * Setup sort model props.
     */
    const [sortModel, setSortModel] = useState([
        { field: 'urgency', sort: 'asc' },
        { field: 'priority', sort: 'asc' },
        { field: 'dateCreated', sort: 'asc' }
    ]);

    /**
     * Sorts the table by urgency, priority, and dateCreated.
     */
    const sortMostUrgent = () => {
        setSortModel([
            { field: 'urgency', sort: 'asc' },
            { field: 'priority', sort: 'asc' },
            { field: 'dateCreated', sort: 'asc' }
        ]);
    }

    const handleApprove = (id, approvalLevel) => {
        let newLevel = '';

        if (approvalLevel === 'Pending Coding') {
            newLevel = 'Pending Timing';
        } else if (approvalLevel === 'Pending Timing') {
            newLevel = 'Pending Scheduling';
        }

        if (newLevel !== '') {
            axios.post('/api/auth/requisition/approve', { id: id, approvalLevel: newLevel })
                .then((res) => {
                    showSnack('Approved requisition', 'success');
                })
                .catch((err) => {
                    // console.log(err.response.data.message)
                    if (err.response.data.message === 'outdated') {
                        showSnack('Synced', 'info');
                    } else {
                        showSnack('Something went wrong!', 'error');
                    }
                })
                .finally(() => {
                    fetchData();
                })
        }
    }

    const handleReject = (id, approvalLevel) => {
        let newLevel = '';

        if (approvalLevel === 'Pending Timing') {
            newLevel = 'Pending Coding';
        }

        if (newLevel !== '') {
            axios.post('/api/auth/requisition/reject', { id: id, approvalLevel: newLevel })
                .then((res) => {
                    showSnack('Rejected requisition', 'success');
                })
                .catch((err) => {
                    if (err.response.data.message === 'outdated') {
                        showSnack('Synced', 'info');
                    } else {
                        showSnack('Something went wrong!', 'error');
                    }
                })
                .finally(() => {
                    fetchData();
                })
        }
    }

    const handleEdit = (row) => {
        let fullRow = {};

        for (let i of fullData) {
            if (i.id.toString() === row.id) {
                fullRow = i;
            }
        }

        setEditData(fullRow, setEditDialogOpen(true));
    }

    const closeEditDialog = () => {
        setEditDialogOpen(false);
    }

    const openDeleteDialog = (id) => {
        setDeleteId(id);
        setDeleteDialogOpen(true);
    }

    const closeDeleteDialog = () => {
        setDeleteDialogOpen(false);
    }

    return (
        <Box sx={protocolStyle}>
            <DataGrid
                autoHeight
                disableSelectionOnClick
                loading={isLoading}
                getRowClassName={(params) => indicateDate(params.row.dateCreated, params.row.bedBlocking)}
                getRowId={(row) => row.id}
                rows={data}
                sortModel={sortModel}
                onSortModelChange={newSortModel => setSortModel(newSortModel)}
                initialState={{
                    sorting: {
                        sortModel: sortModel
                    }
                }}
                columns={[
                    {
                        flex: 0.5,
                        headerName: 'ID',
                        field: 'id',
                        type: 'number',
                        align: 'center',
                        renderCell: renderCellExpand
                    },
                    {
                        flex: 0.75,
                        headerName: 'Urgency',
                        field: 'urgency',
                        type: 'number',
                        align: 'center',
                        cellClassName: (params) => indicateUrgency(params.value),
                        renderCell: renderCellExpand
                    },
                    {
                        flex: 0.75,
                        headerName: 'Priority',
                        field: 'priority',
                        type: 'number',
                        align: 'center',
                        cellClassName: (params) => indicatePriority(params.value),
                        renderCell: renderCellExpand
                    },
                    {
                        flex: 1.5,
                        headerName: 'Submission Date',
                        field: 'dateCreated',
                        type: 'dateTime',
                        renderCell: renderCellExpand
                    },
                    {
                        flex: 1,
                        headerName: 'Last Name',
                        field: 'patientLastName',
                        renderCell: renderCellExpand
                    },
                    {
                        flex: 1,
                        headerName: 'First Name',
                        field: 'patientFirstName',
                        renderCell: renderCellExpand
                    },
                    {
                        flex: 1.2,
                        headerName: 'Clinical Information',
                        field: 'clinicalInformation',
                        renderCell: renderCellExpand
                    },
                    {
                        flex: 1,
                        headerName: 'Protocols',
                        field: 'protocols',
                        renderCell: renderCellExpand
                    },
                    {
                        flex: 0.75,
                        headerName: 'Confidence',
                        field: 'confidence',
                        type: 'number',
                        align: 'center',
                        renderCell: renderCellExpand
                    },
                    {
                        flex: 1,
                        headerName: 'Sequences',
                        field: 'sequences',
                        renderCell: renderCellExpand
                    },
                    {
                        flex: 0.75,
                        headerName: 'Exam Time',
                        field: 'timing',
                        type: 'number',
                        renderCell: renderCellExpand
                    },
                    {
                        flex: 1,
                        headerName: 'Precaution',
                        field: 'isolationPrecaution',
                        renderCell: renderCellExpand
                    },
                    {
                        flex: 0.75,
                        headerName: 'Ward',
                        field: 'ward',
                        renderCell: renderCellExpand
                    },
                    {
                        flex: 'auto',
                        headerName: 'PHIN',
                        field: 'phin',
                        renderCell: renderCellExpand
                    },
                    {
                        flex: 'auto',
                        headerName: 'Bed Blocking',
                        field: 'bedBlocking',
                        renderCell: renderCellExpand
                    },
                    {
                        flex: 'auto',
                        headerName: 'Status',
                        field: 'approvalLevel',
                        renderCell: renderCellExpand
                    },
                    {
                        flex: "auto",
                        minWidth: 296,
                        field: "Button",
                        disableColumnMenu: true,
                        sortable: false,
                        align: "right",
                        headerClassName: classes.gridHeaderSeperatorless,
                        renderHeader: () => (
                            <></>
                        ),
                        renderCell: (params) => (
                            <ButtonGroup size="small" variant="contained">
                                <Button color="primary" onClick={() => { handleApprove(params.row.id, params.row.approvalLevel) }} disabled={params.row.approvalLevel === 'Pending Scheduling'}>
                                    Accept
                                </Button>
                                <Button color="secondary" onClick={() => { handleReject(params.row.id, params.row.approvalLevel) }} disabled={params.row.approvalLevel === 'Pending Coding'}>
                                    Reject
                                </Button>
                                <Button style={{ backgroundColor: "#4F7942" }} color="primary" onClick={() => { handleEdit(params.row) }}>
                                    Edit
                                </Button>
                                <Button style={{ backgroundColor: '#000000', color: '#FFFFFF' }} onClick={() => { openDeleteDialog(params.row.id) }}>
                                    Cancel
                                </Button>
                            </ButtonGroup>
                        )
                    }
                ]}
                components={{
                    Toolbar: () => (
                        <GridToolbarContainer>
                            <div style={{ display: "flex", alignContent: "center", borderBottom: "1px solid #0000003B", width: "100%" }}>
                                <div style={{ display: "flex", flex: 1.5, alignContent: "center" }}>
                                    <div style={{ flex: 1.5 }}>
                                        <Button
                                            size="small"
                                            color="primary"
                                            variant="contained"
                                            onClick={sortMostUrgent}
                                            style={{ marginBottom: "4px" }}
                                            InputProps={{
                                                className: classes.toolbarButton,
                                            }}
                                        >
                                            Sort by most urgent
                                        </Button>
                                        <Button
                                            size="small"
                                            color="primary"
                                            variant="contained"
                                            onClick={fetchData}
                                            style={{ marginBottom: "4px", marginLeft: '4px' }}
                                            InputProps={{
                                                className: classes.toolbarButton,
                                            }}
                                        >
                                            Refresh
                                        </Button>
                                    </div>
                                    <div style={{ flex: 1.5 }}></div>
                                    <div style={{ flex: 1.5 }}></div>
                                </div>
                            </div>
                        </GridToolbarContainer>
                    )
                }}
            />

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackClose}
            >
                <Alert
                    severity={snackSeverity}
                    onClose={handleSnackClose}
                    sx={{ width: '100%' }}
                >
                    {snackMessage}
                </Alert>
            </Snackbar>

            <DeleteDialog open={deleteDialogOpen} closeFunction={closeDeleteDialog} id={deleteId} showSnack={showSnack} update={fetchData} />

            {editData && protocols &&
                <WorklistEditDialog open={editDialogOpen} closeFunction={closeEditDialog} data={editData} protocols={protocols} update={fetchData} showSnack={showSnack} />
            }
        </Box>
    )
}