import React, { useEffect, useState } from 'react';
import axios from 'axios'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import protocolStyle from './supportFunction/protocolStyle';
import { Scheduler } from './Scheduler';
import {
  Grid,
  Paper,
  Button,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  ButtonGroup,
  Typography
} from '@material-ui/core'
import { Alert, Box, Snackbar } from '@mui/material';
import { DeleteDialog, RescheduleDialog } from './helpers';

const useStyles = makeStyles({
  collapseButtonStyle: {
    width: '100%',
    justifyContent: 'left',
    color: 'white',
    background: '#777',
    '&:hover': {
      background: '#555'
    },
    '&:active': {
      background: '#555'
    }
  },
  gridHeaderSeperatorless: {
    //backgroundColor: '#64B5F6',
    '& > .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
      width: '0px'
    }
  },
  toolbarButton: {
    marginLeft: '3px',
    marginTop: '4px',
    top: 0
  }
});

// const styles = theme => ({
//     paperTable: {
//         height: '600px',
//         width: '100%',
//         overflow: 'auto'
//     }
// });

// This does not do anything, and I just used it as a reference for how to set up the event list.
let eventsTest = [{
  title: 'test',
  startDateTime: new Date(),
  endDateTime: new Date(),
  allDay: false,
}];

/**
 * This function returns the Scheduling page.
 * @returns {JSX.Element}
 * @constructor
 */
const MyCalendar = () => {
  const classes = useStyles();
  const localizer = momentLocalizer(moment)

  const [details, setDetails] = useState(null);
  const [data, setData] = useState([]);
  const [fullData, setFullData] = useState({});

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [rescheduleDialogOpen, setRescheduleDialogOpen] = useState(false);
  const [rescheduleId, setRescheduleId] = useState(null);

  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = () => {
    axios.get('/api/auth/calendar')
      .then((res) => {
        setFullData(res.data.requisitions);

        let acc = [];

        for (let r of res.data.requisitions) {
          let sequences = '';
          let protocols = '';

          for (let sequence of r.sequences) {
            if (sequences === '') {
              sequences += sequence.sequenceID.sequence;
            } else {
              sequences += ', ' + sequence.sequenceID.sequence;
            }
          }

          for (let protocol of r.protocols) {
            if (protocols === '') {
              protocols += protocol.protocolID.protocol;
            } else {
              protocols += ', ' + protocol.protocolID.protocol;
            }
          }

          acc.push(
            {
              ...r,
              protocols: protocols,
              sequences: sequences,
              startDateTime: new Date(r.startDateTime),
              endDateTime: new Date(r.endDateTime),
              title: r.patientFirstName + ' ' + r.patientLastName + ' - ' + protocols,
              allDay: false
            }
          );
        }

        setData(acc);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  /**
   * Set up the initial strings in the calendar.
   * Resource: https://github.com/jquense/react-big-calendar/issues/191
   * @type {{date: string, next: string, week: string, work_week: string, previous: string, tomorrow: string, agenda: string, yesterday: string, allDay: string, month: string, today: string, showMore: (function(*): string), noEventsInRange: string, time: string, event: string, day: string}}
   */
  let defaultMessages = {
    date: 'Date',
    time: 'Time',
    event: 'Event',
    allDay: 'All Day',
    week: 'Week',
    work_week: 'Work Week',
    day: 'Day',
    month: 'Month',
    previous: '<',
    next: '>',
    yesterday: 'Yesterday',
    tomorrow: 'Tomorrow',
    today: 'Today',
    agenda: 'Agenda',

    noEventsInRange: 'There are no events in this range.',

    showMore: total => `+${total} more`,
  }

  /**
   * Setup snackbar props.
   * snackbarOpen: Open state for snackbar popup messages.
   * snackMessage: Message to display on snackbar.
   * snackMessage: Type of snackbar to show. Affects color and icon.
   * Values: 'success', 'error', 'warning', 'info'.
   */
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState();
  const [snackSeverity, setSnackSeverity] = useState();

  /**
   * Shows snackbar.
   * @param message The message to be displayed on snackbar.
   * @param severity The type of snackbar to show. Types: 'success', 'error', 'warning', 'info'.
   */
  const showSnack = (message, severity) => {
    setSnackMessage(message);
    setSnackSeverity(severity);
    setSnackbarOpen(true);
  }

  /**
   * Handles snackbar close event.
   * @param event
   * @param reason
   */
  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  /**
   * Setup sort model props.
   */
  const [sortModel, setSortModel] = useState([
    { field: 'urgency', sort: 'asc' },
    { field: 'priority', sort: 'asc' },
    { field: 'dateCreated', sort: 'asc' }
  ]);

  /**
   * Sorts the table by urgency, priority, and dateCreated.
   */
  const sortMostUrgent = () => {
    setSortModel([
      { field: 'urgency', sort: 'asc' },
      { field: 'priority', sort: 'asc' },
      { field: 'dateCreated', sort: 'asc' }
    ]);
  }

  const eventStyleGetter = (event, start, end, isSelected) => ({
    style: {
      backgroundColor: event.complete ? '#D3D3D3' : // backgroundColor: new Date() > end || event.complete ? '#D3D3D3' :
        (
          event.scanner === 1 ? '#FF0100' : event.scanner === 2 ? '#61B100' : null
        )
    }
  });

  const handleSelectEvent = (e) => {
    setDetails(e);
  }

  const openDeleteDialog = () => {
    if (details !== null) {
      setDeleteId(details.id);
      setDeleteDialogOpen(true);
    }
  }

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
  }

  const openRescheduleDialog = () => {
    if (details !== null) {
      setRescheduleId(details.id);
      setRescheduleDialogOpen(true);
    }
  }

  const closeRescheduleDialog = () => {
    setRescheduleDialogOpen(false);
  }

  const completeEvent = () => {
    axios.post('/api/auth/calendar', {'id': details.id})
      .then((res) => {
        showSnack('Completed event', 'success');
        fetchData();
        setDetails(null);
      })
      .catch((err) => {
        showSnack('Something went wrong!', 'error');
      })
  }

  return (
    <Box sx={protocolStyle}>
      <Box sx={{ display: 'flex', flexDirection: 'column', margin: '8px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ margin: '8px', width: '75%' }}>
            <Calendar
              selectable
              defaultView='week'
              defaultDate={new Date()}
              localizer={localizer}
              events={data}
              startAccessor='startDateTime'
              endAccessor='endDateTime'
              style={{ height: 800 }}
              onSelectEvent={handleSelectEvent}
              messages={defaultMessages}
              eventPropGetter={eventStyleGetter}
            />
          </Box>

          <Box sx={{ margin: '8px', width: '25%' }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow style={{ backgroundColor: '#64B5F6' }}>
                    <TableCell>
                      <Grid container direction='colums'>
                        <Grid item xs={12}>
                          <Typography style={{ fontWeight: 'bold' }}>Appointment Details</Typography>
                        </Grid>
                        <Grid item container xs={12}>
                          <ButtonGroup size='small' variant='contained'>
                            <Button color='primary' onClick={openRescheduleDialog} disabled={details === null}>Reschedule</Button>
                            <Button color='primary' onClick={completeEvent} disabled={details === null || details.complete === true}>Complete</Button>
                            <Button color='secondary' onClick={openDeleteDialog} disabled={details === null}>Cancel</Button>
                          </ButtonGroup>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <Paper style={{ height: '736px', width: '100%', overflow: 'auto' }}>
                  <TableBody>
                    <TableRow><TableCell>Precaution: <b>{details !== null ? details.isolationPrecaution.isolationPrecaution : ''}</b></TableCell></TableRow>
                    <TableRow><TableCell>Ward: <b>{details !== null ? details.ward : ''}</b></TableCell></TableRow>
                    <TableRow><TableCell>Protocol: <b>{details !== null ? details.protocols : ''}</b></TableCell></TableRow>
                    <TableRow><TableCell>Sequences: <b>{details !== null ? details.sequences : ''}</b></TableCell></TableRow>
                    <TableRow><TableCell>Clinician: <b>{details !== null ? details.clinician : ''}</b></TableCell></TableRow>
                    <TableRow><TableCell>Feedback: <b>{details !== null ? details.feedback : ''}</b></TableCell></TableRow>
                    <TableRow><TableCell>Mobility requirement: <b>{details !== null ? details.mobilityRequirement.mobilityRequirement : ''}</b></TableCell></TableRow>
                    <TableRow><TableCell>Sedation requirement: <b>{details !== null ? details.sedationRequirement.sedationRequirement : ''}</b></TableCell></TableRow>
                    <TableRow><TableCell>Additional comments: <b>{details !== null ? details.additionalComments : ''}</b></TableCell></TableRow>
                    <TableRow><TableCell>Scanner: <b>{details !== null ? details.scanner : ''}</b></TableCell></TableRow>
                    <TableRow><TableCell>Gender: <b>{details !== null ? details.gender : ''}</b></TableCell></TableRow>
                    <TableRow><TableCell>Weight: <b>{details !== null ? details.weight : ''}</b></TableCell></TableRow>
                    <TableRow><TableCell>Height: <b>{details !== null ? details.height : ''}</b></TableCell></TableRow>
                    <TableRow><TableCell>Date of birth: <b>{details !== null ? details.dob : ''}</b></TableCell></TableRow>
                    <TableRow><TableCell>Date created: <b>{details !== null ? new Date(details.dateCreated).toLocaleString() : ''}</b></TableCell></TableRow>
                    <TableRow><TableCell>Length {'('}minutes{')'}: <b>{details !== null ? details.timing : ''}</b></TableCell></TableRow>
                    <TableRow><TableCell>ID: <b>{details !== null ? details.id : ''}</b></TableCell></TableRow>
                  </TableBody>
                </Paper>
              </Table>
            </TableContainer>
          </Box>
        </Box>


        {(localStorage.getItem('role') === '1' || localStorage.getItem('role') === '-2') &&
          <Grid xs={12}>
            <Scheduler update={fetchData} />
          </Grid>
        }
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackClose}
      >
        <Alert
          severity={snackSeverity}
          onClose={handleSnackClose}
          sx={{ width: '100%' }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>

      <DeleteDialog open={deleteDialogOpen} closeFunction={closeDeleteDialog} id={deleteId} showSnack={showSnack} update={fetchData} />

      {details &&
        <RescheduleDialog open={rescheduleDialogOpen} closeFunction={closeRescheduleDialog} id={rescheduleId} showSnack={showSnack} update={fetchData} data={details} />
      }
    </Box>
  )
}

export default MyCalendar;