import React, { useEffect, useState } from 'react';
import { Box } from "@material-ui/core";
import BarGraphUrgency from "./BarGraphUrgency";
import BarGraphStatus from "./BarGraphStatus";
import TotalPendingRequisition from "./TotalInQueue";
import CompletedYesterday from "./CompletedYesterday";
import TotalQueueTime from "./TotalQueueTime";
import CovidPositive from "./CovidPositive";
import AverageCompletionTime from "./AverageCompletionTime";
import BarGraphCompletionTimeUrgency from "./AverageCompletionTimeUrgency";
import LineChartRequisitionByTime from "./RequisitionByTime";
import RecentlyCompleted from "./RecentlyCompleted";
import axios from 'axios';

const KPIsPage = () => {
  const [kpiData, setKpiData] = useState({});

  useEffect(() => {
    // Fetch KPI data from your API
    axios.get('/api/kpis')  // Replace '/api/kpis' with the actual API endpoint
      .then(response => {
        setKpiData(response.data);
      })
      .catch(error => {
        console.error('Error fetching KPI data:', error);
      });
  }, []);

  return (
  <div>
    <h1 align="center">KPIs Page</h1>
    <Box sx={{ display: 'flex', flexDirection: 'column', margin: '8px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ margin: '8px', width: '25%' }}><TotalPendingRequisition data={kpiData.totalPendingRequisition} /></Box>
          <Box sx={{ margin: '8px', width: '25%' }}><CompletedYesterday data={kpiData.completedYesterday} /></Box>
          <Box sx={{ margin: '8px', width: '25%' }}><TotalQueueTime data={kpiData.totalQueueTime} /></Box>
          {/* <Box sx={{ margin: '8px', width: '20%' }}><AverageCompletionTime data={kpiData.averageCompletionTime} /></Box>
           This is not needed as it does not really make sense as there are many ranges of data used to make this and it will 
           default to the average value of the data */}
          <Box sx={{ margin: '8px', width: '25%' }}><CovidPositive data={kpiData.covidPositive} /></Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ margin: '8px', width: '50%' }}><BarGraphUrgency data={kpiData.barGraphUrgency} /></Box>
          <Box sx={{ margin: '8px', width: '50%' }}><BarGraphStatus data={kpiData.barGraphStatus} /></Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ margin: '8px', width: '50%', height: 'auto' }}>
            <BarGraphCompletionTimeUrgency data={kpiData.barGraphCompletionTimeUrgency} />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', margin: '8px', width: '50%' }}>
            <Box sx={{ height: '50%', marginBottom: '8px' }}>
              <LineChartRequisitionByTime data={kpiData.lineChartRequisitionByTime} />
            </Box>
            <Box sx={{ height: '50%' }}>
              <RecentlyCompleted />
            </Box>
          </Box>
      </Box>
    </Box>
  </div>
  );
};

export default KPIsPage;
