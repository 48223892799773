import React from 'react';
import {
    ResponsiveContainer,
    PieChart,
    Pie,
    Tooltip
} from 'recharts';
import {
    Divider,
    Typography,
    Paper,
} from '@material-ui/core';
import axios from 'axios';
import useSWR from 'swr';
// import { AccessTimeSharp } from '@material-ui/icons';

const PieChartLocation = () => {
    const Fetcher = (url) =>
        axios
            .get(url)
            .then((response) => {
                return response.data.requisitionBodyLocation;
            })
            .catch((err) => {
                console.log(err);
            });

    const Profiler = () => {
        const { data, error } = useSWR('/api/analytics/requisition_body_location', Fetcher, { refreshInterval: 60000});

        if (!error && data) {
            return (<PieGraph data={data} />)
        } else {
            return (<PieGraph data={[{ name: '', count: 0, fill: '#000000' }]} />)
        }
    };

    const PieGraph = (props) => {
        return (
            <Paper style={{ width: '100%', height: '380px', padding: '20px', paddingBottom: '10px' }}>
                <ResponsiveContainer width='100%' height={327}>
                    <PieChart >
                        <Pie
                            data={props.data}
                            dataKey='count'
                            nameKey='name'
                            cx='50%'
                            cy='50%'
                            outerRadius={100}
                            fill='#2e4a98'
                            label={({
                                cx,
                                cy,
                                midAngle,
                                innerRadius,
                                outerRadius,
                                value,
                                index,
                            }) => {
                                const Radian = Math.PI / 180;
                                const radius = 25 + innerRadius + (outerRadius - innerRadius);
                                const x = cx + radius * Math.cos(-midAngle * Radian);
                                const y = cy + radius * Math.sin(-midAngle * Radian);

                                return props.data[index].count !== 0 ? (
                                    <text
                                        x={x}
                                        y={y}
                                        fill='#2e4a98'
                                        textAnchor={x > cx ? 'start' : 'end'}
                                        dominantBaseLine='central'
                                    >
                                        {props.data[index].name} ({value})
                                    </text>
                                ) : (
                                    props.data[index].count
                                );
                            }}
                        />
                        <Tooltip />
                    </PieChart>
                </ResponsiveContainer>

                <Typography variant='h5'>Requisitions by Anatomical Location</Typography>
                {/* 
                <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />

                <div style={{ color: '#999999', fontSize: '12px', display: 'inline-flex', lineHeight: '22px' }}>
                    <AccessTimeSharp style={{ width: '20px', height: '20px', marginRight: '5px' }} />Updated at {new Date().toLocaleTimeString()}
                </div>
                */}
            </Paper>
        );
    };

    return (
        <Profiler />
    );
};

export default PieChartLocation;
