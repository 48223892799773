import React, { PureComponent } from 'react';
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell, LabelList } from 'recharts';
import { Paper, Typography, Grid } from "@mui/material";

const colorMapping = {
  "abdomen": "#004C6D",
  "msk": "#3D708F",
  "neuro": "#6996B3",
  "spine": "#94BED9",
  // Add more mappings as needed
};

const CustomLegend = () => (
  <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginTop: 10 }}>
    {Object.keys(colorMapping).map((type) => (
      <div key={type} style={{ display: 'flex', alignItems: 'center', marginRight: 15 }}>
        <div style={{ width: 20, height: 20, backgroundColor: colorMapping[type], marginRight: 5 }}></div>
        <Typography variant="body2" style={{ marginRight: 20 }}>{type}</Typography>
      </div>
    ))}
  </div>
);


export default class MyBarChartComponent extends PureComponent {
  state = {
    subLocationData: [],
    statusData: [],
    loading: true,
    error: null
  };

  componentDidMount() {
    axios.get('/api/analytics/get_patients_data_for_bar_chart')
      .then(response => {
        const subLocationData = this.flattenSubLocationData(response.data.requisitionSubLocation);
        const statusData = response.data.PatientsByStatus;
        this.setState({ subLocationData, statusData, loading: false });
      })
      .catch(error => {
        this.setState({ error, loading: false });
      });
  }

  flattenSubLocationData(subLocationData) {
    return subLocationData.flatMap(item => 
      item.subtypes.map(subtype => ({
        name: subtype.name, // Removed the type from the name
        count: subtype.count,
        type: item.type
      }))
    );
  }

  renderChart(data, title) {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" angle={-15} />
          <YAxis />
          <Tooltip />
          <Bar dataKey="count">
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colorMapping[entry.type]} />
            ))}
            <LabelList dataKey="count" position="top" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  }

  render() {
    const { subLocationData, loading, error } = this.state;

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error loading data</div>;

    return (
      <Paper sx={{ width: '100%', padding: '20px', paddingBottom: '10px' }}>
        <Grid item>
          <Typography variant='h5'>Requisition by Sublocation</Typography>
        </Grid>
        {this.renderChart(subLocationData, 'Requisition by Sublocation')}
        <CustomLegend />
      </Paper>
    );
  }
}
