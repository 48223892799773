// RadiologyReportPage.js
import React, { useEffect, useState } from 'react';
import PieChartBodyPart from "./PieChartBodyPart";
import PieChartLocation from "./PieChartLocation";
import axios from 'axios';
import BarGraphStatus from "./BarGraphStatus";
import BarGraphRequisitionsBP from "./BarGraphRequisitionsBP";
import { Box } from "@material-ui/core";

const RadiologyReportPage = () => {
  const [reportData, setReportData] = useState({});

  useEffect(() => {
    // Fetch Radiology Report data from your API
    // I'm not sure that this needs to be in here as we are not pulling any data from the API -Q
    axios.get('/api/radiology-report')  // Replace '/api/radiology-report' with the actual API endpoint
      .then(response => {
        setReportData(response.data);
      })
      .catch(error => {
        console.error('Error fetching Radiology Report data:', error);
      });
  }, []);

  return (

    <Box sx={{ display: 'flex', flexDirection: 'column', margin: '8px' }}>
      <h1 align="center">Radiology Report Page</h1>
            
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ margin: '8px', width: '100%' }}><BarGraphRequisitionsBP /></Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ margin: '8px', width: '100%' }}><PieChartLocation data={reportData.pieChartLocation} /></Box>
      </Box>
      {/*
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ margin: '8px', width: '100%' }}><PieChartBodyPart data={reportData.pieChartBodyPart} /></Box>
      </Box>

       Include other components with their respective data props */}
    </Box>

  );
};

export default RadiologyReportPage;
