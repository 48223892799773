import React, { useEffect, useState } from 'react';
import { Divider, Paper, Typography } from '@mui/material'
import {
    CartesianGrid,
    XAxis,
    YAxis,
    LabelList,
    BarChart,
    Bar,
    ResponsiveContainer,
    Cell,
    Label,
    Tooltip
} from 'recharts';
import axios from 'axios';
import useSWR from 'swr';
import { AccessTimeSharp } from '@material-ui/icons';

const TotalQueueTime = () => {
    const fetcher = (url) =>
        axios.get(url)
            .then((response) => {
                return response.data.PatientsTotalQueueTime;
            })
            .catch((err) => {
                console.log(err);
            });

    const Profiler = () => {
        const { data, error } = useSWR('/api/analytics/requisition_time', fetcher, { refreshInterval: 60000});

        if (!error && data) {
            return (<TimeFormater data={data} />);
        } else {
            return (<Total data={0} />);
        }
    };

    const TimeFormater = (props) => {
        const totalMinutes = props.data;
        const days = parseInt(totalMinutes/60/8);
        const totalHours = parseInt(totalMinutes/60);
        let formattedTime = "";
        if(days >= 1){
            formattedTime += days + " Days ";
        }
        if(totalHours < 8){
            formattedTime += totalHours + " Hours ";
        }else{
            const hours = totalHours - days*8;
            if(hours > 0){
                formattedTime += hours + " Hours ";
            }
        }
        if(totalMinutes < 60){
            formattedTime += totalMinutes + " Min.";
        }else{
            const minutes = totalMinutes - totalHours*60
            if(minutes > 0){
                formattedTime += minutes + " Min.";
            }
        }
        return(
            <Total data={formattedTime} />
        )

    }

    const Total = (props) => {
        return (
            <Paper sx={{ width: '100%', padding: '20px', paddingBottom: '10px' }}>


                <Typography variant='h5'>Total Queue Time: </Typography>
                <Typography variant='h5' style={{color: '#6996B3'}}>{props.data}</Typography>

            </Paper>
        );
    };

    return (
        <Profiler />
    );
};

export default TotalQueueTime;