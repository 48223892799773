import React from 'react';
import { Divider, Paper, Typography } from '@mui/material'
import axios from 'axios';
import useSWR from 'swr';
import { AccessTimeSharp } from '@material-ui/icons';

const CompletedYesterday = () => {
    const fetcher = (url) =>
        axios.get(url)
            .then((response) => {
                return response.data.scannedPreviousDay;
            })
            .catch((err) => {
                console.log(err);
            });

    const Profiler = () => {
        const { data, error } = useSWR('/api/analytics/scanned_previous_day', fetcher, { refreshInterval: 60000});

        if (!error && data) {
            return (<Total data={data} />);
        } else {
            return (<Total data={0} />);
        }
    };

    const Total = (props) => {
        return (
            <Paper sx={{ width: '100%', padding: '20px', paddingBottom: '10px' }}>


                <Typography variant='h5'>Completed Yesterday:</Typography>
                <Typography variant='h5' style={{color: '#6996B3'}}>{props.data}</Typography>
                {/* <Typography variant='subtitle2'>&#8203;</Typography>

                <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />

                <div style={{ color: '#999999', fontSize: '12px', display: 'inline-flex', lineHeight: '22px' }}>
                    <AccessTimeSharp style={{ width: '20px', height: '20px', marginRight: '5px' }} />Updated at {new Date().toLocaleTimeString()}
                </div> */}
            </Paper>
        );
    };

    return (
        <Profiler />
    );
};

export default CompletedYesterday;