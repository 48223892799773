import React, { useEffect, useState } from 'react'
import axios from 'axios'
import renderCellExpand from './supportFunction/renderCellExpand';
import indicateUrgency from './supportFunction/indicateUrgency';
import indicatePriority from './supportFunction/indicatePriority';
import protocolStyle from './supportFunction/protocolStyle';
import { Button, makeStyles } from '@material-ui/core'
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { Box } from '@mui/material';

const useStyles = makeStyles({
    table: {
        minWidth: 650
    },
    contain: {
        marginTop: 10
    },
    buttonStyle: {
        margin: 5
    },
    toolbarButton: {
        marginLeft: "3px",
        marginTop: "4px",
        top: 0
    },
});

/**
 * This function returns the outstanding protocol page.
 * @returns {JSX.Element}
 */
export const OutstandingProtocols = () => {
    const classes = useStyles();

    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = () => {
        axios.get('/api/auth/outstanding')
            .then((res) => {
                let acc = [];

                for (let r of res.data.requisitions) {
                    let sequences = '';
                    let protocols = '';

                    for (let sequence of r.sequences) {
                        if (sequences === '') {
                            sequences += sequence.sequenceID.sequence;
                        } else {
                            sequences += ', ' + sequence.sequenceID.sequence;
                        }
                    }

                    for (let protocol of r.protocols) {
                        if (protocols === '') {
                            protocols += protocol.protocolID.protocol;
                        } else {
                            protocols += ', ' + protocol.protocolID.protocol;
                        }
                    }

                    acc.push(
                        {
                            id: r.id.toString(),
                            urgency: r.urgency.toString(),
                            priority: r.priority.toString(),
                            dateCreated: new Date(r.dateCreated).toLocaleString('en-US', {day: "numeric", year: "numeric", month: "long", hour: "numeric", minute: "numeric"}),
                            isolationPrecaution: r.isolationPrecaution.isolationPrecaution,
                            ward: r.ward,
                            phin: r.phin.toString(),
                            patientFirstName: r.patientFirstName,
                            patientLastName: r.patientLastName,
                            timing: r.timing.toString() + ' minutes',
                            clinicalInformation: r.clinicalInformation,
                            protocols: protocols,
                            sequences: sequences,
                            approvalLevel: r.approvalLevel,
                            confidence: r.confidence,
                            bedBlocking: r.bedBlocking.toString()
                        }
                    );
                }

                setData(acc, setIsLoading(false));
            })
            .catch((err) => {
                console.log(err);
            })
    }


    /**
     * Setup sort model props.
     */
    const [sortModel, setSortModel] = useState([
        { field: 'urgency', sort: 'asc' },
        { field: 'priority', sort: 'asc' },
        { field: 'dateCreated', sort: 'asc' }
    ]);

    /**
     * Sorts the table by urgency, priority, and dateCreated.
     */
    const sortMostUrgent = () => {
        setSortModel([
            { field: 'urgency', sort: 'asc' },
            { field: 'priority', sort: 'asc' },
            { field: 'dateCreated', sort: 'asc' }
        ]);
    }

    return (
        <Box sx={protocolStyle}>
            <DataGrid
                autoHeight
                disableSelectionOnClick
                loading={isLoading}
                getRowId={(row) => row.id}
                rows={data}
                sortModel={sortModel}
                onSortModelChange={newSortModel => setSortModel(newSortModel)}
                initialState={{
                    sorting: {
                        sortModel: sortModel
                    }
                }}
                columns={[
                    {
                        flex: 0.5,
                        headerName: 'ID',
                        field: 'id',
                        type: 'number',
                        align: 'center',
                        renderCell: renderCellExpand
                    },
                    {
                        flex: 0.75,
                        headerName: 'Urgency',
                        field: 'urgency',
                        type: 'number',
                        align: 'center',
                        cellClassName: (params) => indicateUrgency(params.value),
                        renderCell: renderCellExpand
                    },
                    {
                        flex: 0.75,
                        headerName: 'Priority',
                        field: 'priority',
                        type: 'number',
                        align: 'center',
                        cellClassName: (params) => indicatePriority(params.value),
                        renderCell: renderCellExpand
                    },
                    {
                        flex: 1.5,
                        headerName: 'Submission Date',
                        field: 'dateCreated',
                        type: 'dateTime',
                        renderCell: renderCellExpand
                    },
                    {
                        flex: 1,
                        headerName: 'Last Name',
                        field: 'patientLastName',
                        renderCell: renderCellExpand
                    },
                    {
                        flex: 1,
                        headerName: 'First Name',
                        field: 'patientFirstName',
                        renderCell: renderCellExpand
                    },
                    {
                        flex: 1.2,
                        headerName: 'Clinical Information',
                        field: 'clinicalInformation',
                        renderCell: renderCellExpand
                    },
                    {
                        flex: 1,
                        headerName: 'Protocols',
                        field: 'protocols',
                        renderCell: renderCellExpand
                    },
                    {
                        flex: 0.75,
                        headerName: 'Confidence',
                        field: 'confidence',
                        type: 'number',
                        align: 'center',
                        renderCell: renderCellExpand
                    },
                    {
                        flex: 1,
                        headerName: 'Sequences',
                        field: 'sequences',
                        renderCell: renderCellExpand
                    },
                    {
                        flex: 0.75,
                        headerName: 'Exam Time',
                        field: 'timing',
                        type: 'number',
                        renderCell: renderCellExpand
                    },
                    {
                        flex: 1,
                        headerName: 'Precaution',
                        field: 'isolationPrecaution',
                        renderCell: renderCellExpand
                    },
                    {
                        flex: 0.75,
                        headerName: 'Ward',
                        field: 'ward',
                        renderCell: renderCellExpand
                    },
                    {
                        flex: 'auto',
                        headerName: 'PHIN',
                        field: 'phin',
                        renderCell: renderCellExpand
                    },
                    {
                        flex: 'auto',
                        headerName: 'Bed Blocking',
                        field: 'bedBlocking',
                        renderCell: renderCellExpand
                    },
                    {
                        flex: 'auto',
                        headerName: 'Status',
                        field: 'approvalLevel',
                        renderCell: renderCellExpand
                    },
                ]}
                components={{
                    Toolbar: () => (
                        <GridToolbarContainer>
                            <div style={{ display: "flex", alignContent: "center", borderBottom: "1px solid #0000003B", width: "100%" }}>
                                <div style={{ display: "flex", flex: 1.5, alignContent: "center" }}>
                                    <div style={{ flex: 1.5 }}>
                                        <Button
                                            size="small"
                                            color="primary"
                                            variant="contained"
                                            onClick={sortMostUrgent}
                                            style={{ marginBottom: "4px" }}
                                            InputProps={{
                                                className: classes.toolbarButton,
                                            }}
                                        >
                                            Sort by most urgent
                                        </Button>
                                        <Button
                                            size="small"
                                            color="primary"
                                            variant="contained"
                                            onClick={fetchData}
                                            style={{ marginBottom: "4px", marginLeft: '4px' }}
                                            InputProps={{
                                                className: classes.toolbarButton,
                                            }}
                                        >
                                            Refresh
                                        </Button>
                                    </div>
                                    <div style={{ flex: 1.5 }}></div>
                                    <div style={{ flex: 1.5 }}></div>
                                </div>
                            </div>
                        </GridToolbarContainer>
                    )
                }}
            />
        </Box>
    );
}