import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {
    Button,
    ButtonGroup,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    TextField,
    MenuItem,
    Select,
    InputLabel,
    Grid,
    Typography,
    ListItem,
    List,
    ListItemText,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    Radio,
    Checkbox,
    ListItemIcon,
} from '@material-ui/core'
import {
    KeyboardDateTimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment from "moment";

export const DeleteDialog = (props) => {
    const [deleteReason, setDeleteReason] = useState('');
    const [errDelete, setErrDelete] = useState('');

    useEffect(() => {
        setDeleteReason('');
    }, [props.id])

    const handleDeleteReasonChange = (e) => {
        setDeleteReason(e.target.value);
    }

    const handleCancel = () => {
        let errors = 0;

        if (deleteReason === '') {
            setErrDelete('Required');
            errors++;
        } else if (deleteReason.length > 200) {
            setErrDelete('Maximum 200 characters');
            errors++;
        } else {
            setErrDelete('');
        }

        if (errors === 0) {
            axios.delete('/api/auth/requisition', { params: { id: props.id, cancelReason: deleteReason } })
                .then((res) => {
                    props.showSnack('Requisition canceled', 'info');
                })
                .catch((err) => {
                    props.showSnack('Something went wrong!', 'error');
                })
                .finally(() => {
                    props.update();
                })

            props.closeFunction();
        }
    }

    return (
        <Dialog open={props.open} onClose={props.closeFunction}>
            <DialogTitle style={{ backgroundColor: '#64B5F6' }}>Confirm Cancelation</DialogTitle>
            <DialogContent>
                <DialogContentText>This action is not reversible.</DialogContentText>

                <TextField
                    required
                    id='reason'
                    name='reason'
                    label='Reason'
                    fullWidth
                    value={deleteReason}
                    onChange={handleDeleteReasonChange}
                    error={errDelete !== ''}
                    helperText={errDelete}
                    variant='outlined'
                    style={{ width: '500px' }}
                />
            </DialogContent>

            <DialogActions>
                <ButtonGroup size="small" variant="contained">
                    <Button onClick={handleCancel} color="secondary">Confirm</Button>
                    <Button onClick={props.closeFunction} color="primary">Cancel</Button>
                </ButtonGroup>
            </DialogActions>
        </Dialog>
    );
}




export const SchedulerDialog = (props) => {
    const [values, setValues] = useState(null);
    const [errDate, setErrDate] = useState(false);

    useEffect(() => {
        setValues({ ...props.data, startDateTime: moment(Date.now()).utc().format(), endDateTime: '', scanner: 1 });
    }, [props.data])

    const save = () => {
        if (!errDate) {
            axios.post('/api/auth/scheduler', { id: parseInt(values.id), startDateTime: values.startDateTime, timing: parseInt(values.timing), scanner: parseInt(values.scanner) })
                .then((res) => {
                    props.showSnack('Scheduled requisition', 'success');
                })
                .catch((err) => {
                    if (err.response.data.message === 'outdated') {
                        props.showSnack('Synced', 'info');
                    } else {
                        props.showSnack('Something went wrong!', 'error');
                    }
                })
                .finally(() => {
                    props.update();
                    props.updateCalendar();
                    props.closeFunction();
                })
        }
    }

    const handleScannerChange = (e) => { setValues({ ...values, scanner: parseInt(e.target.value) }) }
    const handleDateChange = (e) => { setValues({ ...values, startDateTime: moment(e).utc().format() }) }

    const dateError = (e) => {
        console.log(e)
        if (e === 'Invalid Date Format') {
            setErrDate(true);
        } else {
            setErrDate(false);
        }
    }

    return (
        <>
            {values &&
                <Dialog open={props.open} onClose={props.closeFunction}>
                    <DialogTitle style={{ backgroundColor: '#64B5F6' }}>Schedule Requisition {props.data.id}</DialogTitle>
                    <DialogContent>
                        <Grid container direction='column' spacing={2}>
                            <Grid item>
                                <MuiPickersUtilsProvider utils={MomentUtils} locale="en">
                                    <KeyboardDateTimePicker
                                        value={values.startDateTime}
                                        onChange={handleDateChange}
                                        label="Date and Time"
                                        onError={e => dateError(e)}
                                        minDate={new Date()}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>

                            <Grid item>
                                <InputLabel id="scanner-label">Scanner</InputLabel>
                                <Select
                                    labelId="scanner-label"
                                    id="scanner"
                                    name="scanner"
                                    onChange={handleScannerChange}
                                    value={values.scanner}
                                    fullWidth
                                    label="Scanner"
                                >
                                    <MenuItem value={1}>Scanner #1</MenuItem>
                                    <MenuItem value={2}>Scanner #2</MenuItem>
                                </Select>
                            </Grid>


                            <Grid item>
                                <Typography>Patient name:</Typography>
                                <Typography><b>{values.patientFirstName} {values.patientLastName}</b></Typography>
                            </Grid>

                            <Grid item>
                                <Typography>Exam Length:</Typography>
                                <Typography><b>{values.timing}</b></Typography>
                            </Grid>

                            <Grid item>
                                <Typography>Urgency:</Typography>
                                <Typography><b>{values.urgency}</b></Typography>
                            </Grid>

                            <Grid item>
                                <Typography>Protocol:</Typography>
                                <Typography><b>{values.protocols}</b></Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <ButtonGroup size='small' variant='contained'>
                            <Button onClick={save} color='primary'>Schedule</Button>
                            <Button onClick={props.closeFunction} color='secondary'>Cancel</Button>
                        </ButtonGroup>
                    </DialogActions>

                </Dialog>
            }
        </>
    );
}




export const RescheduleDialog = (props) => {
    const [values, setValues] = useState(null);
    const [errDate, setErrDate] = useState(false);

    useEffect(() => {
        setValues({ ...props.data, startDateTime: moment(props.data.startDateTime).utc().format(), endDateTime: '', scanner: props.data.scanner });
    }, [props.data])

    const save = () => {
        if (!errDate) {
            axios.post('/api/auth/scheduler', { id: parseInt(values.id), startDateTime: values.startDateTime, timing: parseInt(values.timing), scanner: parseInt(values.scanner) })
                .then((res) => {
                    props.showSnack('Rescheduled requisition', 'success');
                })
                .catch((err) => {
                    if (err.response.data.message === 'outdated') {
                        props.showSnack('Synced', 'info');
                    } else {
                        props.showSnack('Something went wrong!', 'error');
                    }
                })
                .finally(() => {
                    props.update();
                    props.closeFunction();
                })
        }
    }

    const handleScannerChange = (e) => { setValues({ ...values, scanner: parseInt(e.target.value) }) }
    const handleDateChange = (e) => { setValues({ ...values, startDateTime: moment(e).utc().format() }) }

    const dateError = (e) => {
        console.log(e)
        if (e === 'Invalid Date Format') {
            setErrDate(true);
        } else {
            setErrDate(false);
        }
    }

    return (
        <>
            {values &&
                <Dialog open={props.open} onClose={props.closeFunction}>
                    <DialogTitle style={{ backgroundColor: '#64B5F6' }}>Reschedule Requisition {props.data.id}</DialogTitle>
                    <DialogContent>
                        <Grid container direction='column' spacing={2}>
                            <Grid item>
                                <MuiPickersUtilsProvider utils={MomentUtils} locale="en">
                                    <KeyboardDateTimePicker
                                        value={values.startDateTime}
                                        onChange={handleDateChange}
                                        label="Date and Time"
                                        onError={e => dateError(e)}
                                        minDate={new Date()}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>

                            <Grid item>
                                <InputLabel id="scanner-label">Scanner</InputLabel>
                                <Select
                                    labelId="scanner-label"
                                    id="scanner"
                                    name="scanner"
                                    onChange={handleScannerChange}
                                    value={values.scanner}
                                    fullWidth
                                    label="Scanner"
                                >
                                    <MenuItem value={1}>Scanner #1</MenuItem>
                                    <MenuItem value={2}>Scanner #2</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <ButtonGroup size='small' variant='contained'>
                            <Button onClick={save} color='primary'>Schedule</Button>
                            <Button onClick={props.closeFunction} color='secondary'>Cancel</Button>
                        </ButtonGroup>
                    </DialogActions>

                </Dialog>
            }
        </>
    );
}




export const WorklistEditDialog = (props) => {
    const [values, setValues] = useState(null);

    const [errPriority, setErrPriority] = useState('');
    const [errTiming, setErrTiming] = useState('');
    const [errFeedback, setErrFeedback] = useState('');

    useEffect(() => {
        let newSequences = [];
        let newProtocols = [];

        for (let sequence of props.data.sequences) {
            newSequences.push(sequence.sequenceID)
        }

        for (let protocol of props.data.protocols) {
            newProtocols.push(protocol.protocolID)
        }

        setValues(
            {
                clinicalInformation: props.data.clinicalInformation,
                timing: props.data.timing,
                urgency: props.data.urgency,
                priority: props.data.priority,
                feedback: props.data.feedback,
                sequences: newSequences,
                protocols: newProtocols,
                bedBlocking: props.data.bedBlocking
            }
        )
    }, [props])

    const handleValueChange = e => {
        setValues({ ...values, [e.target.name]: e.target.value });
    }

    const updateBedBlocking = (bedBlocking) => {
        setValues({...values, bedBlocking: bedBlocking})
        console.log(values.bedBlocking)
    }

    const updateProtocols = (protocols) => {
        setValues({ ...values, protocols: protocols });
    }

    const updateSequences = (sequences) => {
        setValues({ ...values, sequences: sequences });
    }

    const save = () => {
        let errors = 0;

        if (values.priority === '') {
            errors++;
            setErrPriority('Required');
        } else if (values.priority < 1) {
            errors++;
            setErrPriority('Minimum 1');
        } else if (values.priority > 40) {
            errors++;
            setErrPriority('Maximum 40');
        } else {
            setErrPriority('');
        }

        if (values.timing === '') {
            errors++;
            setErrTiming('Required');
        } else if (values.timing < 1) {
            errors++;
            setErrTiming('Minimum 1');
        } else if (values.timing > 480) {
            errors++;
            setErrTiming('Maximum 480');
        } else {
            setErrTiming('');
        }

        if (values.feedback.length > 1500) {
            errors++;
            setErrFeedback('Maximum 1500 characters')
        } else {
            setErrFeedback('');
        }

        if (errors === 0) {
            let protocols = [];
            let sequences = [];

            for (let i of values.protocols) {
                protocols.push(i.id);
            }

            for (let i of values.sequences) {
                sequences.push(i.id);
            }

            let formatted = {
                id: props.data.id,
                urgency: parseInt(values.urgency),
                priority: parseInt(values.priority),
                timing: parseInt(values.timing),
                feedback: values.feedback,
                protocols: protocols,
                sequences: sequences,
                bedBlocking: values.bedBlocking,
                role: localStorage.getItem('role')
            }

            axios.put('/api/auth/worklist', { data: formatted })
                .then(res => {
                    props.showSnack('Updated requisition', 'success');
                })
                .catch(err => {
                    if (err.response.data.message === 'outdated') {
                        props.showSnack('Synced', 'info');
                    } else {
                        props.showSnack('Something went wrong!', 'error');
                    }
                })
                .finally(() => {
                    props.update();
                    props.closeFunction();
                })
        }
    }

    return (
        <>
            {values &&
                <Dialog open={props.open} onClose={props.closeFunction} PaperProps={{ className: { width: '80%' } }} fullWidth>
                    <DialogTitle style={{ backgroundColor: '#64B5F6' }}>Edit Requisition {props.data.id}</DialogTitle>
                    <DialogContent>
                        <Grid container direction={'row'} spacing={2} style={{ marginTop: '1px', marginBottom: '10px' }}>
                            <Grid item xs={12}>
                                <TransferListProtocol left={props.protocols} right={values.protocols} type={props.data.anatomicalLocation.id} onChange={updateProtocols} />
                            </Grid>

                            <Grid item xs={12}>
                                <TransferListSequence key={values.protocols} right={values.sequences} protocols={values.protocols} fullProtocols={props.protocols} onChange={updateSequences} />
                            </Grid>

                            <Grid item xs={6}>
                                <FormLabel labelPlacement='start'><small>Urgency</small></FormLabel>
                                <RadioGroup
                                    row
                                    required
                                    id='urgency'
                                    name='urgency'
                                    value={parseInt(values.urgency)}
                                    onChange={handleValueChange}
                                >
                                    <FormControlLabel value={1} control={<Radio />} label='1' />
                                    <FormControlLabel value={2} control={<Radio />} label='2' />
                                    <FormControlLabel value={3} control={<Radio />} label='3' />
                                    <FormControlLabel value={4} control={<Radio />} label='4' />
                                </RadioGroup>
                                <p style={{ margin: 0 }}><small><b>1</b> (2-3 hours) <b>2</b> (24 hours) <b>3</b> (24-72 hours) <b>4</b> (2 Weeks)</small></p>
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    row
                                    required
                                    fullWidth
                                    id='priority'
                                    name='priority'
                                    label='Priority'
                                    type='number'
                                    value={values.priority}
                                    onChange={handleValueChange}
                                    InputProps={{ inputProps: { min: 1, max: 40 } }}
                                    error={errPriority !== ''}
                                    helperText={errPriority}
                                >
                                </TextField>
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    required
                                    id='timing'
                                    name='timing'
                                    label='Timing'
                                    fullWidth
                                    type='number'
                                    value={values.timing}
                                    onChange={handleValueChange}
                                    InputProps={{ inputProps: { min: 1 } }}
                                    error={errTiming !== ''}
                                    helperText={errTiming}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label='Bed blocking'
                                    checked={values.bedBlocking}
                                    onChange={() => updateBedBlocking(!values.bedBlocking)}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id='clinicalInformation'
                                    name='clinicalInformation'
                                    label='Relevant Clinical History'
                                    fullWidth
                                    multiline={true}
                                    value={values.clinicalInformation}
                                    onChange={handleValueChange}
                                    disabled
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    id='feedback'
                                    name='feedback'
                                    label='Feedback'
                                    fullWidth
                                    value={values.feedback}
                                    onChange={handleValueChange}
                                    error={errFeedback !== ''}
                                    helperText={errFeedback}
                                />
                            </Grid>
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <ButtonGroup size='small' variant='contained'>
                            <Button onClick={save} color='primary'>Save</Button>
                            <Button onClick={props.closeFunction} color='secondary'>Cancel</Button>
                        </ButtonGroup>
                    </DialogActions>
                </Dialog >
            }
        </>
    );
}

//-----------------------------------------------------------------------------------------------------------
function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

const TransferListProtocol = (props) => {
    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);

    useEffect(() => {
        let normalLeft = [];
        let normalRight = [];

        for (let i of props.left) {
            if (i.type === props.type) {
                normalLeft.push({ id: i.id, protocol: i.protocol })
            }
        }

        for (let i of props.right) {
            normalRight.push({ id: i.id, protocol: i.protocol })
        }

        for (let i of normalRight) {
            let index = normalLeft.map((e) => { return e.id }).indexOf(i.id);

            if (index !== -1) normalLeft.splice(index, index + 1);
        }

        setLeft(normalLeft);
        setRight(normalRight);
    }, [])

    useEffect(() => {
        props.onChange(right);
    }, [right])

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const customList = (items) => (
        <List style={{ height: 200, overflow: 'auto', border: '1px solid rgba(0,0,0,0.4)', borderRadius: '5px', marginTop: '4px' }}>
            {items.map((value) => {
                const labelId = `transfer-list-item-${value}-label`;

                return (
                    <ListItem
                        key={value.id}
                        role='listitem'
                        button
                        onClick={handleToggle(value)}
                    >
                        <ListItemIcon>
                            <Checkbox
                                checked={checked.indexOf(value) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{
                                    'aria-labelledby': labelId,
                                }}
                            />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={value.protocol} />
                    </ListItem>
                );
            })}
            <ListItem />
        </List>
    );

    return (
        <>
            <Typography>Protocols</Typography>

            <Grid container direction='row' spacing={1}>
                <Grid item xs={5}>
                    {customList(left)}
                </Grid>

                <Grid container item direction='column' alignItems='center' justify='center' spacing={2} xs={2}>
                    <Grid item>
                        <Button
                            sx={{ my: 0.5 }}
                            variant='contained'
                            size='small'
                            color='primary'
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label='move selected right'
                        >
                            &gt;
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button
                            sx={{ my: 0.5 }}
                            variant='contained'
                            size='small'
                            color='primary'
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label='move selected left'
                        >
                            &lt;
                        </Button>
                    </Grid>
                </Grid>

                <Grid item xs={5}>
                    {customList(right)}
                </Grid>
            </Grid>
        </>
    );
}

const TransferListSequence = (props) => {
    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);

    useEffect(() => {
        let normalLeft = [];
        let normalRight = props.right;
        let protocols = [];
        let sequences = [];

        // Get selected protocols
        for (let i of props.protocols) {
            for (let j of props.fullProtocols) {
                if (i.id === j.id) {
                    protocols.push(j);
                }
            }
        }

        // Get sequences that are on selected protocols (pool)
        for (let i of protocols) {
            for (let j of i.sequences) {
                if (normalLeft.filter(e => e.id === j.id).length === 0) {
                    normalLeft.push(j);
                    sequences.push(j);
                }
            }
        }

        // Remove from right if not in pool
        let inMaster = normalRight.filter(cItem => {
            return sequences.find(aItem => {
                return cItem.id === aItem.id
            })
        })

        normalRight = inMaster;

        // Deduplicate left
        for (let i of normalRight) {
            let index = normalLeft.map((e) => { return e.id }).indexOf(i.id);

            if (index !== -1) normalLeft.splice(index, index + 1);
        }

        setLeft(normalLeft);
        setRight(normalRight);
    }, [])

    // Propagate seletion back up to parent
    useEffect(() => {
        props.onChange(right);
    }, [right])

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const customList = (items) => (
        <List style={{ height: 200, overflow: 'auto', border: '1px solid rgba(0,0,0,0.4)', borderRadius: '5px', marginTop: '4px' }}>
            {items.map((value) => {
                const labelId = `transfer-list-item-${value}-label`;

                return (
                    <ListItem
                        key={value.id}
                        role='listitem'
                        button
                        onClick={handleToggle(value)}
                    >
                        <ListItemIcon>
                            <Checkbox
                                checked={checked.indexOf(value) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{
                                    'aria-labelledby': labelId,
                                }}
                            />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={value.sequence} />
                    </ListItem>
                );
            })}
            <ListItem />
        </List>
    );

    return (
        <>
            <Typography>Sequences</Typography>

            <Grid container direction='row' spacing={1}>
                <Grid item xs={5}>
                    {customList(left)}
                </Grid>

                <Grid container item direction='column' alignItems='center' justify='center' spacing={2} xs={2}>
                    <Grid item>
                        <Button
                            sx={{ my: 0.5 }}
                            variant='contained'
                            size='small'
                            color='primary'
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label='move selected right'
                        >
                            &gt;
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button
                            sx={{ my: 0.5 }}
                            variant='contained'
                            size='small'
                            color='primary'
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label='move selected left'
                        >
                            &lt;
                        </Button>
                    </Grid>
                </Grid>

                <Grid item xs={5}>
                    {customList(right)}
                </Grid>
            </Grid>
        </>
    );
}