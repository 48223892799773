//  Registration, Login, Logout Submissions
export const USER_CHECKUSERNAME_SUCCESS = "USER_CHECKUSERNAME_SUCCESS";
export const USER_CHECKUSERNAME_FAIL = "USER_CHECKUSERNAME_FAIL";

export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";

//  Requisition Submission
export const DATA_SENT_SUCCESS = "DATA_SENT_SUCCESS"
export const DATA_SENT_FAIL = "DATA_SENT_FAIL"
