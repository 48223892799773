import React, { useEffect, useState } from "react";
import { Divider, Paper, Typography, Grid, Select, MenuItem } from "@mui/material";
import moment from "moment";
import {
    CartesianGrid,
    XAxis,
    YAxis,
    Line,
    ResponsiveContainer,
    Label,
    Tooltip,
    LineChart,
} from "recharts";
import axios from "axios";
import useSWR, { mutate } from "swr";
import { AccessTimeSharp } from "@material-ui/icons";

const LineChartRequisitionByTime = () => {
    const [timeframe, setTimeframe] = useState(7);

    const timeframeChange = (e) => {
        setTimeframe(e.target.value);
    }

    useEffect(() => {
        mutate('/api/analytics/requisition_by_time');
    }, [timeframe])

    const fetcher = (url) =>
        axios.post(url, {timeframe: timeframe})
            .then((response) => {
                let counts = [];
                let res = response.data.requisitionByTime;

                for (let i = 0; i < res.length; i++) {
                    counts.push({
                        name: new Date(res[i].name).getTime(),
                        count: res[i].count,
                    });
                }

                return counts;
            })
            .catch((err) => {
                console.log(err);
            });

    const Profiler = () => {
        const { data, error } = useSWR("/api/analytics/requisition_by_time", fetcher, { refreshInterval: 60000 });

        if (!error && data) {
            return <LineGraph data={data} />;
        } else {
            return <LineGraph data={[{ name: "", count: 0 }]} />;
        }
    };

    function formatXAxis(tickItem) {
        console.log(tickItem)
        return moment.utc(new Date(tickItem)).format("ll");
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload.length) {
            return (
                <div style={{ backgroundColor: '#FFFFFF', border: '1px solid #D0D0D0', paddingRight: '10px', paddingLeft: '10px' }}>
                    <p>Day: {formatXAxis(payload[0].payload.name)}</p>
                    <p>Total: {payload[0].payload.count}</p>
                </div>
            )
        }

        return null;
    }

    const LineGraph = (props) => {
        return (
            <Paper sx={{ width: "100%", padding: "20px", paddingBottom: "10px" }}>
                <ResponsiveContainer height={200} width={"100%"}>
                    <LineChart
                        data={props.data}
                        margin={{ right: 20, left: -10, bottom: 20, top: 4 }}
                    >
                        <CartesianGrid strokeDasharray="1 1" />

                        <XAxis dataKey="name" tickFormatter={formatXAxis}>
                            <Label value="Day" position="bottom" offset={-4} />
                        </XAxis>

                        <YAxis>
                            <Label value="Requisitions" angle={-90} />
                        </YAxis>

                        <Tooltip content={<CustomTooltip />} />
                        <Line type="monotone" dataKey="count" stroke="#3D708F" />
                    </LineChart>
                </ResponsiveContainer>

                <Grid container direction='row' wrap='nowrap'>
                    <Grid item>
                        <Typography variant='h5'>Requistions Created Per Day</Typography>
                    </Grid>

                    <Grid item sx={{ marginLeft: 'auto', marginBottom: '-20px'}}>
                        <Select
                            onChange={timeframeChange}
                            value={timeframe}
                            size='small'
                            width={150}
                        >
                            <MenuItem value={7}>Last 7 days</MenuItem>
                            <MenuItem value={30}>Last 30 days</MenuItem>
                            <MenuItem value={365}>Last 365 days</MenuItem>
                        </Select>
                    </Grid>
                </Grid>

                {/* 
                <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />

                <div
                    style={{
                        color: "#999999",
                        fontSize: "12px",
                        display: "inline-flex",
                        lineHeight: "22px",
                    }}
                >
                    <AccessTimeSharp
                        style={{ width: "20px", height: "20px", marginRight: "5px" }}
                    />
                    Updated at {new Date().toLocaleTimeString()}
                </div>
                */}
            </Paper>
        );
    };

    return <Profiler />;
};

export default LineChartRequisitionByTime;
